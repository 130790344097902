<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label position="floating">Nombres</ion-label>
        <ion-input type="text" readonly required v-model="dataEnter.name" />
      </ion-item>
      <ion-item>
        <ion-thumbnail slot="start">
          <img :src="imageUrl" alt="" />
        </ion-thumbnail>
        <ion-button type="button" fill="clear" @click="takePhoto">
          <ion-icon slot="start" :icon="camera"></ion-icon>
          <ion-label>Agregar Imagen</ion-label>
          <ion-input
            type="file"
            @ionChange="onFileSelected"
            hidden
            v-model="dataEnter.imagen"
          ></ion-input>
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Obervaciones</ion-label>
        <ion-textarea rows="3" v-model="dataEnter.obs"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button type="submit" expand="block" fill="outline">Enviar</ion-button>
  </form>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonThumbnail,
  IonIcon,
  toastController,
  loadingController,
} from "@ionic/vue";
import { camera } from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraDirection, CameraResultType, CameraSource } from "@capacitor/camera";
import { Geolocation } from "@capacitor/geolocation";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "register-enter-form",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonButton,
    IonThumbnail,
    IonIcon,
  },
  data() {
    return {
      dataEnter: {
        userId: 0,
        name: "",
        imagen: null,
        obs: "",
        coordenadas: {},
      },
      imageUrl: "assets/icon/favicon.png",
      page: "",
      camera,
      timeout: 1000,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.verData();
    if (process.env.MODE == "capacitor") {
      const { PushNotifications } = require("@capacitor/local-notifications");
      console.log(Object.keys(PushNotifications));
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          PushNotifications.register();
        }
      });
    }
  },
  methods: {
    ...mapActions("entrada", ["registerEntrada", "registerSalida"]),

    async submitForm() {
      const fecha = new Date();
      const tiempo = await this.timeConverter(fecha);
      console.log(tiempo);
      this.timeout = 100000;
      let result = "";
      const permitions = await this.checkPermitions();
      if (!permitions.location === "granted") {
        await this.requestPermitions();
      } else {
        const loading = await loadingController.create({
          message: "Enviando información...",
          duration: this.timeout,
        });
        await loading.present();
        const coor = await this.getLocation();
        if (coor !== 'error') {
          if (this.page === "Ingreso") {
            result = await this.registerEntrada({
              entrada: this.dataEnter,
              coordenadas: coor,
            });
          } else {
            result = await this.registerSalida({
              salida: this.dataEnter,
              coordenadas: coor,
            });
          }
          console.log(result);
          if (result.status === 201) {
            this.$router.replace({ name: "home" });
            this.toatsColor = "light";
            this.toatsMessage = "Registro exitoso";
            this.openToast();
          } else if (result.status === 401) {
            this.toatsColor = "danger";
            this.toatsMessage = `¡No autorizado!.<br>¡Inicie sesión!`;
            this.openToast();
          } else if (result.status === 500) {
            this.toatsColor = "danger";
            this.toatsMessage = `¡Error interno del servidor!.<br>Contacte al administrador`;
            this.openToast();
          } else if (result.status === 422) {
            let errorMessate = "";
            const errors = await JSON.parse(JSON.stringify(result.data.errors));
            for (const i in errors) {
              console.log(errors[i]);
              errorMessate = await errors[i];
            }
            this.toatsColor = "danger";
            this.toatsMessage = `No se ha enviado toda la información<br>¡Erores!<br>${errorMessate}`;
            await this.openToast();
          } else {
            this.toatsColor = "danger";
            this.toatsMessage = "Error. No se ha realizado el registro";
            this.openToast();
          }
        } else {
          this.toatsColor = "danger";
          this.toatsMessage = "Error. No se puede optener la ubicación";
          this.openToast();
        }
        loading.dismiss();
      }
    },
    onFileSelected(event) {
      this.dataEnter.imagen = event.target.value;
    },
    verData() {
      this.dataEnter.name = this.user.name;
      this.dataEnter.userId = this.user.id;
      this.page = this.$route.params.id;
    },
    async takePhoto() {
      const isAvailable = Capacitor.isPluginAvailable("Camera");
      if (!isAvailable) {
        console.log(isAvailable);
      } else {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          allowEditing: true,
          source: CameraSource.Prompt,
          quality: 60,
          direction:CameraDirection.Rear
        }).catch(() => {
          this.toatsColor = "warning";
          this.toatsMessage = `¡Debes enviar una imagen`;
          this.openToast();
        });

        if (photo) {
          this.imageUrl = photo && photo.dataUrl;
          this.dataEnter.imagen = this.imageUrl;
        }
      }
    },
    async getLocation() {
      let coord = null;
      try{
        const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        errors: true
      });
        coord = {
        latitude: coordinates.coords.latitude,
        longitude: coordinates.coords.longitude,
        timestamp: coordinates.timestamp,
      };
      }catch(err){
        console.error(err);
        coord = "error";
      }
      return coord;
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
    async checkPermitions() {
      const permitions = await Geolocation.checkPermissions();
      return permitions;
    },
    async requestPermitions() {
      const request = await Geolocation.requestPermissions();
      return request.PermisiontState;
    },
    async timeConverter(timestamp) {
      const a = new Date(timestamp);
      const year = a.getFullYear();
      const month = a.getMonth() + 1;
      const date = a.getDate();
      const hour = a.getHours();
      const min = a.getMinutes();
      const sec = a.getSeconds();
      const day = a.getDay();
      const time = `${year}-${month}-${date} ${hour}:${min}:${sec} ${day}`;
      return time;
    },
  },
};
</script>
