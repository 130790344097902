<template>
  <base-layout page-defaul-back-link="/home/Inicio">
  <register-enter-form></register-enter-form>
  </base-layout>
</template>

<script>
import RegisterEnterForm from '../components/employe/RegisterEnterForm.vue'
export default {  
    name: 'salida-page',
    components: {RegisterEnterForm}

}
</script>

<style>

</style>